<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card
                    class="header-card mb-5"
                >
                    <v-card-title class="card-title px-4 mt-1">
                        Filters
                        <v-btn v-if="$store.getters.currentUser.role === 'admin'" dark small color="#027a00" class="my-2 mx-2" @click="onImportClick">
                            <v-icon dark>mdi-file-plus-outline</v-icon>Upload
                        </v-btn>
                        <v-btn v-if="$store.getters.currentUser.role === 'admin'" class="my-2 mx-2" dark small color="#027a00" @click="createMember">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <filters-alternative
                            :sort-options="headers.filter(element => element.sortable)"
                            :filters="filterFields"
                            @reloadFilters="reloadFilters"
                            @filterResults="filterResults"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="members"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td v-for="(field, index) in headers" :key="index">
                        <span v-if="field.text == 'Paron Card'"> {{ getActiveCard(props.item[field.value], 'PARON') }} </span>
                        <span v-else-if="field.text == 'My Market Card'"> {{ getActiveCard(props.item[field.value], 'My Market') }} </span>
                        <span v-else-if="field.text == 'AB Card'"> {{ getActiveCard(props.item[field.value], 'AB') }} </span>
                        <span v-else-if="field.text == 'Team'"> {{ props.item[field.value] ? props.item[field.value].title : '' }} </span>
                        <v-btn v-else-if="field.text == 'Approved'" class="mx-2" dark small :color="props.item.approved ? 'green' : 'red'" outlined @click="toggleApprove(props.item)">
                            <div v-if="props.item.approved && !isDealer">
                                Approved
                            </div>
                            <div v-else>
                                Not Approved
                            </div>
                        </v-btn>
                        <span v-else>
                            <v-icon v-if="typeof props.item[field.value] == 'boolean'" :color="props.item[field.value]? 'green' : 'red'">
                                {{ props.item[field.value] ? 'mdi-check-outline' : 'mdi-close-outline' }}
                            </v-icon>
                            <span v-else>
                                {{ props.item[field.value] }}
                            </span>
                        </span>
                    </td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button v-if="!isDealer" @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        />
        <import-dialog
            :entity-label="'Members'"
            :open-dialog="showImportDialog"
            :successfull-import="successfullImport"
            @close-import-dialog="showImportDialog=false"
            @file-import="onFileImport"
        />
    </v-container>
</template>

<script>
    import moment from 'moment'
    import FiltersAlternative from '@/components/FiltersAlternative'
    import memberService from '@/services/member-service'
    import DeleteButton from '@/components/DeleteButton'
    import ImportDialog from '@/components/ImportDialog'
    import store from '@/store'

    export default {
        name: 'MemberRoute',
        components: { DeleteButton, FiltersAlternative, ImportDialog },
        data: () => {
            return {
                panel: null,
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                members: [],
                filterFields: [
                    {
                        text: 'Member',
                        value: 'member',
                        type: 'text',
                        searchable: true,
                        data: null
                    },
                    {
                        text: 'Number',
                        value: 'number',
                        type: 'text',
                        searchable: true
                    },
                    {
                        text: 'Card',
                        value: 'card',
                        type: 'api',
                        searchable: true,
                        data: null
                    },
                    {
                        text: 'Vip',
                        value: 'vip',
                        type: 'boolean',
                        searchable: true,
                        data: [true, false]
                    },
                    {
                        text: 'Exclusive',
                        value: 'exclusive',
                        type: 'boolean',
                        searchable: true,
                        data: [true, false]
                    },
                    {
                        text: 'Reliable',
                        value: 'reliable',
                        type: 'boolean',
                        searchable: true,
                        data: [true, false]
                    },
                    {
                        text: 'Approved',
                        value: 'approved',
                        type: 'boolean',
                        searchable: true,
                        data: [true, false]
                    }
                ],
                headers: [{
                              text: 'Number',
                              value: 'number',
                              align: 'left',
                              sortable: true
                          },
                          {
                              text: 'Firstname',
                              value: 'firstName',
                              sortable: true
                          },
                          {
                              text: 'Lastname',
                              value: 'lastName',
                              sortable: true
                          },
                          {
                              text: 'Paron Card',
                              value: 'cards',
                              sortable: false
                          },
                          {
                              text: 'My Market Card',
                              value: 'cards',
                              sortable: false
                          },
                          {
                              text: 'AB Card',
                              value: 'cards',
                              sortable: false
                          },
                          {
                              text: 'Vip',
                              value: 'vip',
                              sortable: false
                          },
                          {
                              text: 'Exclusive',
                              value: 'exclusive',
                              sortable: false
                          },
                          {
                              text: 'Reliable',
                              value: 'reliable',
                              sortable: true
                          },
                          {
                              text: 'Recommendation',
                              value: 'recommendation',
                              sortable: false
                          },
                          {
                              text: 'Team',
                              value: 'team',
                              sortable: false
                          },
                          {
                              text: 'Approved',
                              value: 'approved',
                              sortable: true
                          }
                ],
                loading: false,
                sortOption: null,
                order: null,
                showImportDialog: false,
                successfullImport: null,
                filters: null
            }
        },
        computed: {
            isDealer() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer'
            }
        },
        watch: {
            page() {
                var path = `/members?page=${this.page}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
                this.fetchFilteredMembers()
            },
            $route(to, from) {
                if (to.name !== from.name) {
                    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
                    this.fetchFilteredMembers()
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
            this.filters = this.$store.getters.getFilters
            this.sortOption = this.$store.getters.getSortOption
            this.order = this.$store.getters.getSortOrder
        },
        methods: {
            onImportClick() {
                this.showImportDialog = true
            },
            reloadFilters() {
                this.order = 'ASC'
                this.sortOption = null
                this.filters = {}
                this.fetchFilteredMembers()
            },
            getActiveCard(cards, type) {
                var filteredCards = cards.filter(card => (card.active === true && card.type === type))
                let text = ''
                if (filteredCards.length > 0) {
                    filteredCards.forEach(card => {
                        text += card.number + ', '
                    })
                }
                text = text.slice(0, -2)
                return text
            },
            formatDate(date, format) {
                if (!date) {
                    return null
                }
                return moment(date).format(format)
            },
            toggleApprove(member) {
                if (!member.id) {
                    return
                }
                memberService.toggleApproved(member.id).then(() => {
                    member.approved = !member.approved
                })
            },
            handleClick(mode, item) {
                this.$router.push(`/members/${item.id}/${mode}?page=${this.page}`)
            },
            onDelete(item) {
                memberService.deleteMemberById(item.id).then(() => {
                    this.fetchFilteredMembers()
                })
            },
            createMember() {
                this.$router.push(`/members/create?page=${this.page}`)
            },
            fetchFilteredMembers() {
                this.loading = true
                if (this.isDealer) {
                    this.filters.dealer = store.getters.currentUser.dealerId
                }
                memberService.getMembersByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    this.members = resp.data.data
                    this.pageCount = resp.data.last_page
                    this.loading = false
                })
            },
            filterResults(filterObject, sortOption = null, order = 'ASC', page = false) {
                this.sortOption = sortOption
                this.order = order
                this.filters = filterObject
                this.loading = true
                if (page) {
                    this.page = 1
                }
                if (this.isDealer) {
                    this.filters.dealer = store.getters.currentUser.dealerId
                }
                memberService.getMembersByFilter(this.page, filterObject, sortOption, order).then(resp => {
                    if (this.page > this.pageCount) {
                        this.page = 1
                    }
                    this.pageCount = resp.data.last_page
                    this.members = resp.data.data
                    this.loading = false
                })
            },
            onFileImport(file) {
                if (file) {
                    let formData = new FormData()
                    formData.append('file', file)
                    memberService.importFromFile(formData).then(() => {
                        this.successfullImport = true
                        this.showImportDialog = false
                        this.fetchFilteredMembers()
                    }).catch(() => {
                        this.successfullImport = false
                    })
                }
            }
        }
    }
</script>

<style scoped>
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
